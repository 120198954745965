const setVh = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// window.addEventListener('resize', setVh);
setVh();

function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;
	var isVisible;

	// Only completely visible elements return true:
	// isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}

document.addEventListener('DOMContentLoaded', function () {

	const header = document.querySelector('.h'),
		burgerBtn = document.querySelector('.burger'),
		dropdown = document.querySelector('.h__drop-content');

	const stickFunc = () => {
		if (window.scrollY > 0) {
			header.classList.add('h--scrolled');
		} else {
			header.classList.remove('h--scrolled');
		}
	}

	window.addEventListener('scroll', () => {
		requestAnimationFrame(stickFunc)
	});

	burgerBtn.addEventListener('click', () => {
		header.classList.toggle('h--opened');
	});

	document.addEventListener('click', function (event) {
		if (!dropdown.contains(event.target) && !burgerBtn.contains(event.target)) {
			header.classList.remove('h--opened');
		}
	});


	const introSlider = document.querySelector('.intro__slider');
	new Swiper(introSlider, {
		spaceBetween: 10,
		slidesPerView: 'auto'
	})

	/*
	* Popups
	*/
	// Close popup when click on close btn, or click outside popup
	const closePopup = popup => {

		if (popup.hasAttribute('data-delete')) {
			popup.remove();
		} else {
			popup.classList.remove('popup--active');
		}

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function (e) {
		var tg = e.target;

		if (tg.classList.contains('popup')) {
			closePopup(tg);
			return;
		}

		if (tg.classList.contains('popup__close-btn') || tg.hasAttribute('data-close-popup')) {
			var popup = tg.closest('.popup');

			closePopup(popup);
		}
	});

	document.addEventListener('keyup', function (e) {
		if (e.keyCode == 27) {
			const popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}
	});

	// open popups
	document.addEventListener('click', function (e) {
		const target = e.target.closest('[data-popup-target]');

		if (target) {

			const popup = document.querySelector('.' + target.dataset.popupTarget);

			popup.classList.add('popup--active');
			document.body.style.overflow = 'hidden';
		}
	});
	/*========================================================*/


	/*
	* Accordions
	*/
	const accs = document.querySelectorAll('.acc__header'),
		preopenedAcc = document.querySelectorAll('.acc--active');

	accs.forEach(acc => {
		acc.addEventListener('click', function () {
			this.closest('.acc').classList.toggle('acc--active');
			const panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = panel.scrollHeight + 'px';
			}
		});
	});

	preopenedAcc.forEach(acc => {
		const panel = acc.querySelector('.acc__content');
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px';
		}
	})

	const anchorLinks = document.querySelectorAll('[data-anchor-link]');
	anchorLinks.forEach(function (link) {
		link.addEventListener('click', function (e) {

			const href = e.target.href,
				target = href.substring(href.indexOf('#') + 1),
				targetEl = document.getElementById(target);

			if (!targetEl) {
				return;
			}
			e.preventDefault();

			let offset = targetEl.getBoundingClientRect().top + window.scrollY,
				headerHieght = document.querySelector('header').scrollHeight;

			offset -= headerHieght;

			window.scrollTo({ top: offset, behavior: 'smooth' });

			header.classList.remove('h--opened');
		});
	});


	// init phones
	const telephoneInputs = document.querySelectorAll('[data-input-tel]');
	telephoneInputs.forEach(function (telephoneInput) {
		IMask(telephoneInput, {
			mask: '+{7}(000)000-00-00'
		});
	});

	// actions slider
	const actionSlider = document.querySelector('.intro__toolbar');

	if (actionSlider) {
		new Swiper(actionSlider, {
			navigation: {
				prevEl: actionSlider.querySelector('.slider-count__btn--prev'),
				nextEl: actionSlider.querySelector('.slider-count__btn--next')
			},
			on: {
				slideChange: function () {
					actionSlider.querySelector('[data-current-index]').textContent = this.realIndex + 1;
				}
			}
		})
	}

	const photoSliders = document.querySelectorAll('.hslider__slider.swiper');
	photoSliders.forEach(slider => {

		let settings = {};
		if (slider.classList.contains('hslider__slider--auto-width')) {
			settings = {
				spaceBetween: 10,
				slidesPerView: 'auto',
				breakpoints: {
					1024: {
						spaceBetween: 30,
					}
				}
			}
		}

		new Swiper(slider, {
			...settings,
			navigation: {
				prevEl: slider.querySelector('.sliders-btn__btn--prev'),
				nextEl: slider.querySelector('.sliders-btn__btn--next')
			}
		})
	})


	// scroll headers
	const scrollHeader = document.querySelector('.slogan, .sub-header__text');
	if (scrollHeader) {
		gsap.registerPlugin(ScrollTrigger);
		const scrollLines = gsap.utils.toArray('.slogan span, .sub-header__text span');

		scrollLines.forEach(line => {
			const xChange = gsap.fromTo(line,
				{ x: line.dataset.offsetStart },
				{ x: line.dataset.offsetEnd },
			)

			ScrollTrigger.create({
				trigger: line.closest('.slogan, .sub-header__text'),
				start: '-50% 80%',
				end: '150% 20%',
				scrub: true,
				animation: xChange,
				// markers: true
			})
		})
	}


	// clear flat id at recall popup
	const popupFlatId = document.querySelector('[data-flat-id]'),
		recallBtn = document.querySelector('.f__recall-btn');
	recallBtn.addEventListener('click', () => {
		popupFlatId.value = 0;
	})


	// show more .photo-text
	const photoTextBtn = document.querySelector('.photo-text-load-more-btn'),
		photoTextHidden = document.querySelector('.photo-text-hidden-block');

	photoTextBtn?.addEventListener('click', () => {
		const last = document.querySelector('.photo-text--nomgb');
		last?.classList.remove('photo-text--nomgb')
		photoTextBtn.remove();
		photoTextHidden?.classList.remove('photo-text-hidden-block');
	})


	const rantaPopup = document.querySelector('.popup--ranta-recall');
	if (rantaPopup && !sessionStorage.getItem('rantaPopupShown')) {
		const delay = rantaPopup.dataset.delay;
		setTimeout(() => {
			rantaPopup.classList.add('popup--active');
			sessionStorage.setItem('rantaPopupShown', 'true');
		}, delay)
	}

});


document.addEventListener('DOMContentLoaded', () => {

	// Form validation
	function initSimpleForm(form) {

		var requireElements = form.querySelectorAll('[required], .js-validate'),
			submitBtn = form.querySelector('.js-btn-submit'),
			agreeCheck = form.querySelector('.js-form-check-rules'),
			state = submitBtn.textContent;

		function clearFormErrors() {
			var texts = form.querySelectorAll('.input-line__error-text:not(.hidden)');
			texts.forEach(function (text) {
				text.classList.add('hidden');
			});

			var styles = form.querySelectorAll('.has-error');
			styles.forEach(function (style) {
				style.classList.remove('has-error');
			});
		}

		function removeSingleErrorNode() {
			var isErrorNode = this.classList.contains('has-error');

			if (isErrorNode) {
				this.classList.remove('has-error');
				var errorText = this.closest('.input-line').querySelector('.input-line__error-text');
				if (errorText) {
					errorText.classList.add('hidden');
				}
			}
		}

		function scrollToFirstError() {
			var errEl = form.querySelector('.has-error');

			if (errEl && !isScrolledIntoView(errEl)) {
				errEl.closest('.input-line').scrollIntoView({
					behavior: 'smooth'
				});
			}
		}

		function applyAjaxResult(data) {
			if (!data.err) {

				if (data.elementsToHideInSuccess) {
					data.elementsToHideInSuccess.forEach(function (selector) {
						var el = form.querySelector(selector);
						el.style.display = 'none';
					})
				}
				form.reset();

				if (data.successHtml) {
					var successTarget = form.querySelector(data.successTarget);
					successTarget.innerHTML = data.successHtml;
					successTarget.style.display = '';
				}

			} else {

				data.elements.forEach(function (el) {
					if (el.errName == "captcha") {
						const errWrp = form.querySelector('.js-form-common-error');
						errWrp.textContent = el.message;
						errWrp.classList.remove('hidden');
					} else {
						var errEl = form.querySelector('[name="' + el.errName + '"]');
						if (errEl) {
							errEl.classList.add('has-error');
							var errText = errEl.closest('.input-line').querySelector('.input-line__error-text');
							errText.textContent = el.message;
							errText.classList.remove('hidden');
						}
					}
				});

				scrollToFirstError();
			}

			submitBtn.textContent = state;
			submitBtn.disabled = false;
		}

		requireElements.forEach(function (el) {
			el.addEventListener('input', removeSingleErrorNode);
			el.addEventListener('change', removeSingleErrorNode);
		});

		if (agreeCheck) {
			agreeCheck.addEventListener('change', function () {
				submitBtn.disabled = !this.checked;
			});
		}

		form.setAttribute('novalidate', '');
		form.addEventListener('submit', function (e) {
			e.preventDefault();

			clearFormErrors();

			var bError = false;
			requireElements.forEach(function (el) {

				if (!el.checkValidity() || 
					(el.hasAttribute('data-input-tel') && el.value.length < 16) ||
					(el.hasAttribute('data-input-tel') && el.value[3] != 9)
				) {
					var errorType = el.validity.valueMissing ? 'valueMissing' : 'valueInvalid';
					var errorEl = el.closest('.input-line').querySelector('.input-line__error-text');
					if (errorEl) {
						errorEl.innerHTML = el.dataset[errorType];
						errorEl.classList.remove('hidden');
					}
					el.classList.add('has-error');

					bError = true;
				}
			});

			if (bError) {
				scrollToFirstError();
				return;
			}

			let data = new FormData(form);
			submitBtn.disabled = true;
			submitBtn.textContent = submitBtn.dataset.wait;

			fetch(form.getAttribute('action'), {
				method: form.getAttribute('method'),
				body: data
			}).then(response => {
				return response.json();
			}).then(data => {
				applyAjaxResult(data);
			}).catch(function (error) {
				console.error(error);
			});

			// send comagic for quiz form
			if (form.hasAttribute('data-quiz-form') && typeof Comagic != 'undefined') {
				
				const name = data.get('name');
				const phone = data.get('phone');
				const maybeMessage = data.get('message');
				const message = maybeMessage ? maybeMessage : data.get('subject');
				data.delete('name');
				data.delete('phone');
				if (maybeMessage) {
					data.delete('message');
				} else {
					data.delete('subject');
				}

				const dataObj = {};
    		data.forEach((value, key) => (dataObj[key] = value));

				Comagic.addOfflineRequest({
					name: name,
					phone: phone,
					message: message,
					form_name: form.getAttribute('name'),
					user_fields: {
						...dataObj
					}
				});
			}
		});
	}

	var forms = document.querySelectorAll('form.js-simple-submit');
	if (!forms.length) {
		return;
	}

	forms.forEach(form => {
		initSimpleForm(form);
	});
})


// LAR preloader
document.addEventListener('DOMContentLoaded', () => {

	const preloader = document.querySelector('.preloader');

	if (!preloader) {
		return;
	}

	const percentEl = preloader.querySelector('.preloader__percent'),
		imageCloser = preloader.querySelector('.preloader__image-closer'),
		images = [];

	let progress = 0,
		loaded_items = 0;

	function gatherImages() {

		document.querySelectorAll('*:not(script,source)')
			.forEach(elm => {
				let url = '';

				if (elm.style.backgroundImage) {
					url = elm.style.backgroundImage;
				} else if (elm.hasAttribute('src')) {
					url = elm.src;
				}

				url = url.replace("url(\"", "");
				url = url.replace("url(", "");
				url = url.replace("\")", "");
				url = url.replace(")", "");

				if (url.length > 0 && /(gif|jpeg|jpg|png|svg)$/.test(url)) {
					images.push(url);
				}
			});
	}

	gatherImages();
	const total_items = images.length;

	function update_progress() {

		progress = total_items ? Math.round(loaded_items / total_items * 100) : 100;
		update_preloader(progress);
		if (progress >= 100) {
			loading_complete();
		}
	}

	function image_loaded() {
		loaded_items++;
		update_progress();
	}

	for (let i = 0; i < total_items; i++) {

		const img = document.createElement('img');
		setTimeout(() => {
			img.src = images[i];
		}, i * 100)

		img.addEventListener('load', () => {
			image_loaded();
		});
		img.addEventListener('error', () => {
			image_loaded();
		});
	}

	function loading_complete() {
		setTimeout(() => {
			preloader.remove();
		}, 500);
	}

	function update_preloader(percent) {
		const progress = 100 - percent;
		percentEl.textContent = `${percent}%`;
		imageCloser.style.width = `${progress}%`;
	}
});


document.addEventListener('DOMContentLoaded', function() {
    var phoneInputs = document.querySelectorAll('input[name="tel"]');

    phoneInputs.forEach(function(phoneInput) {

        phoneInput.addEventListener('focus', function() {
            var input = this;
            if (input.value === '') {
                input.value = '+7(';
                // Устанавливаем курсор после обновления значения через событие 'input'
            } else {
                setCursorPositionAfterPrefix(input);
            }
        });

        phoneInput.addEventListener('input', function() {
            var input = this;
            // Проверяем, если значение равно '+7('
            if (input.value === '+7(') {
                setCursorPositionAfterPrefix(input);
            }
        });

        phoneInput.addEventListener('click', function() {
            var input = this;
            var prefixLength = '+7('.length;
            if (input.selectionStart < prefixLength) {
                setCursorPositionAfterPrefix(input);
            }
        });

        phoneInput.addEventListener('keydown', function(e) {
            var caretPos = this.selectionStart;
            if (
                (e.key === 'Backspace' && caretPos <= '+7('.length) ||
                (e.key === 'Delete' && caretPos <= '+7('.length)
            ) {
                e.preventDefault();
            }
        });

        function setCursorPositionAfterPrefix(input) {
            var prefixLength = '+7('.length;
            if (input.setSelectionRange) {
                input.focus();
                input.setSelectionRange(prefixLength, prefixLength);
            }
        }
    });
});